import { combineReducers } from 'redux';
import { profileReducer } from './reducers/profileReducer';
import { customerReducer } from './reducers/customerReducer';
import { agentReducer } from './reducers/agentReducer';
import { buildingReducer } from './reducers/buildingReducer';

import { scheduleReducer } from './reducers/scheduleReducer';

import { orderReducer } from './reducers/orderReducer';
import { productReducer } from './reducers/productReducer';
import { manifestReducer } from './reducers/manifestReducer';
import { cartItemReducer } from './reducers/cartItemReducer';

export const appReducer = combineReducers(
	{
		profileReducer,
		customerReducer,
		agentReducer,
		buildingReducer,
		scheduleReducer,


		orderReducer,
		productReducer,
		manifestReducer,
		cartItemReducer
	});