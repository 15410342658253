import { CART_ITEMS as ActionTypes } from '../actionTypes';


//TODO: sets the cart items the user has in current or saved status

const initialState = {
    currentItems: [],
    savedItems: [],
    cartTotals: {
        monthly: 0.00,
        today: 0.00,
        carrier: 0.00
    }
};


export const cartItemReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.SET_CART_ITEMS:

            return {
                ...state,
                currentItems: action.payload?.currentItems,
                savedItems: action.payload?.savedItems,
                cartTotals: action.payload?.cartTotals,
            };
        default:
            return state;
    };
};