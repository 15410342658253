import { MANIFEST as ActionTypes } from '../actionTypes';

const initialState = {
    manifest: [], // array of manifest items - contains all possible combinations
    appFilter: {
        carriers: [],
        products: [],
        manufacturers: []
    },
    // singleItem user selected to view
    selectedItem: {
        item: {

        },
        selectedOptions: {
            cartItemId: '', // only used if loaded from the cart
            manifestId: '',  // we get the selected values - 
        }

    }
};


export const manifestReducer = (state = initialState, action) => {

    switch (action.type) {


        case ActionTypes.SET_MANIFEST:
            return {
                manifest: action.payload,
                appFilter: { ...state.appFilter, products: action.payload?.products }
            };
        case ActionTypes.SET_FILTER:
            return {
                ...state,
                appFilter: action.payload
            };
        default:
            return state;
    };
};