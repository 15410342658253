import { PRODUCTS as ActionTypes } from '../actionTypes';
import { productInit } from '../../services/productApi';

const initialState = {
	product: productInit,
	selectedOptions: {
		manifestId: '',
		cartItemId: '',
		//carrierId: '',
		productId: '',
		//purchaseTypeId: '',
		//ratePlanId: '',
		//ratePlanname: '',
		//ratePlanPrice: 0,
		quantity: '',
		//availablePurchaseTypes: [],
		installedApps: [],
		notes: ''
	}
};


export const productReducer = (state = initialState, action) => {

	switch (action.type) {


		case ActionTypes.SET_PRODUCT:
			// set the product graph from the db - should have all the details and images from product
			//! NOT THE MANIFEST	
			let selectedOptions = initialState.selectedOptions;
			if (action.payload.selectedOptions != null) {
				selectedOptions = action.payload.selectedOptions;
			}

			return {
				...state,
				product: action.payload.product,
				selectedOptions: selectedOptions
			};

		case ActionTypes.SET_SELECTED_OPTIONS:



			return {
				...state,
				selectedOptions: action.payload
			};

		default:
			return state;
	};
};



