import * as appConsts from '../appConsts';



export const handleFetch = async (url, options, authRequired) => {
	let token = null;

	const storedToken = sessionStorage.getItem(appConsts.SESSION_STORAGE.TOKEN);

	if (storedToken) {
		token = "Bearer " + storedToken.replace(/['"]+/g, '');
	}

	const headerAuth = {
		'Authorization': token,
		'Content-Type': 'application/json',
		'Domain': appConsts.DOMAIN
	};
	const header = {
		'Content-Type': 'application/json'
	};

	let fetchOptions = Object.assign(
		{},
		{
			headers: authRequired === true ? headerAuth : header
		},
		options
	);


	try {

		let callResponse = await fetch(url, fetchOptions);

		let test = handleStatus(callResponse);
		return test;

	} catch (e) {

		let errorObject = {
			data: null,
			hasError: true,
			title: 'API is down'
		};

		// let errorObject = {
		// 	title: 'Invalid credentials',
		// 	message: 'You must login to continue'

		// };
		return Promise.resolve(errorObject);

	};

};



const handleStatus = async (response) => {
	try {
		// get the status response and pass back the data		
		let errorObject = {};

		// if(response.status >= 400) {
		// 	switch(response.status) {
		// 		case 400:

		// 			errorObject.title = 'Invalid credentials';
		// 			errorObject.message = 'You must login to continue';
		// 			break;
		// 		case 401:
		// 			errorObject.title = 'Invalid credentials';
		// 			errorObject.message = 'You must login to continue';
		// 			break;
		// 		case 403:
		// 			//message = 'Access Forbidden';
		// 			errorObject.title = 'Access Denied';
		// 			errorObject.message = 'You cannot access this module';
		// 			break;
		// 		case 404:
		// 			//message = 'Sorry! the data you are looking for could not be found';
		// 			errorObject.title = 'Data or Item not found';
		// 			errorObject.message = 'Sorry! the data you are looking for could not be found';
		// 			break;
		// 		default: {

		// 			//message =
		// 			//error.response && error.response.data ? error.response.data['message'] : error.message || error;
		// 		}
		// 	}

		// 	return Promise.reject(errorObject);
		// }



		if (response.status !== 200) {
			//300 or 500 range
			// test
			return Promise.resolve({ data: undefined, hasError: true, emptyMessage: 'No results found' });
		}

		if (response.status == 401) {
			//300 or 500 range
			// test
			//return Promise.resolve({ data: undefined, hasError: true, message: 'No results found' });
		}

		let payload = await response.json();

		let myReturnObject = {
			data: payload
		};

		return Promise.resolve(myReturnObject);

	}
	catch (e) {

		debugger;
	}
};