// @flow
import React, { useEffect, Suspense } from 'react';

const loading = () => <div className=""></div>;

const DefaultLayout = (props) => {
    useEffect(() => {


    }, []);

    // get the child view which we would like to render
    const children = props.children || null;

    return (
        <>
            <Suspense fallback={loading()}>
                <div style={{ backgroundColor: '#ffffff' }} >
                    {children}
                </div>
            </Suspense>
        </>
    );
};
export default DefaultLayout;
